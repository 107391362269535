<template>
  <div class="page-root tools-chexin-root">
    <div class="tools-banner-wrapper">
      <div class="tools-banner">
        <div class="text">
          <div class="en">CAR MODEL LIBRARY</div>
          <div class="zh">提供完善的车型库数据服务</div>
          <div class="en">DATA SERVICE</div>
          <div class="small">包含国内流通的所有车型和配置信息 解决车型识别的关键问题为您提供全品牌车型信息查询</div>
          <div class="line"></div>
        </div>
        <div class="box"></div>
        <div class="img">
          <img src="@/assets/组 2373@2x.png" />
        </div>
        <div class="hidden-md-and-up" style="color: #000; margin-top: 10px; font-size: 13px" @click="search('sim')">
          查看报告样例 >
        </div>
      </div>
    </div>
    <div class="tabs-wrapper">
      <div class="title">车型库查询</div>
      <div class="list-bar">
        <div class="list">
          <cyt-car-select class="hidden-md-and-down" @carSelected="value => this.cascaderValue = value[2]" :upperOption="true"/>
          <van-field
            class="hidden-md-and-up"
            v-model="fieldValue"
            is-link
            readonly
            placeholder="品牌/车系/车型"
            @click="show = true"
          />
          <el-input placeholder="验证码" v-model="value2"> </el-input>
          <img width="100" height="40" :src="'data:image/jpeg;base64,' + captchas.img" @click="toCaptchas" />
        </div>
        <div class="butt hidden-md-and-down">
          <div @click="search">开始查询</div>
          <div @click="search('sim')">查看报告样例 ></div>
        </div>
        <div class="butt-sm hidden-md-and-up">
          <div @click="search">开始查询</div>
        </div>
      </div>
    </div>
    <van-popup v-model="show" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="品牌/车系/车型"
        :options="options"
        @close="show = false"
        @change="onChange"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>
<script>
import CytCarSelect from "@/components/CytCarSelect/CytCarSelect.vue";
import './styles/mobile.scss';
import './styles/pc/pc.scss';
import { get } from '@/utils/axios';
import json from './data.json';
export default {
  components: {
    CytCarSelect
  },
  methods: {
    //手机端选择车型
    onChange({ value, selectedOptions, tabIndex }) {
      if (tabIndex === 0) {
        get(`/system/brand/list/s/${value}`).then((res) => {
          this.options[selectedOptions[0].index].children = res.data.map((item, i) => {
            return {
              index: i,
              text: item.vehicleSeriesName,
              value: item.vehicleSeriesId,
              children: [],
            };
          });
        });
      }
      if (tabIndex === 1) {
        get(`/system/brand/list/t/${value}`).then((res) => {
          this.options[selectedOptions[0].index].children[selectedOptions[1].index].children = res.data.map((item) => {
            return {
              text: item.vehicleTypeName,
              value: item.vehicleTypeId,
            };
          });
        });
      }
    },
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join('/');
    },
    //获取验证码
    toCaptchas() {
      get('/captchas').then((res) => {
        this.captchas = res;
      });
    },
    //开始查询
    search(params) {
      if (params == 'sim') {
        this.$router.push({
          name: 'Tools-cxResult',
          params: json.data,
        });
      } else {
        if ((!this.value1.length && !this.cascaderValue) || !this.value2) {
          this.$message({
            message: '查询参数尚未填写完整 ！',
            type: 'warning',
          });
        } else {
          get(
            `/system/type/detailGrouping/${this.cascaderValue || this.value1[2]}?code=${this.value2}&uuid=${
              this.captchas.uuid
            }`
          ).then((res) => {
            if (res.code == 200) {
              this.$router.push({
                name: 'Tools-cxResult',
                params: res.data,
              });
            } else {
              this.$message.error(res.msg);
              this.toCaptchas();
            }
          });
        }
      }
    },
  },
  mounted() {
    this.toCaptchas();
    get('/system/brand/list').then((res) => {
      this.options = res.rows.map((item, i) => {
        return {
          index: i,
          text: item.brandName,
          value: item.brandId,
          children: [],
        };
      });
    });
  },
  data() {
    return {
      show: false,
      fieldValue: '', //手机端车型展示值
      cascaderValue: '', //手机端车型value
      options: [], //手机端车型list
      value1: [], //pc端车型展示值
      value2: '', //验证码input
      captchas: {}, //验证码返回值
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level, value } = node;
          let url = '',
            nodes = [];
          if (level == 2) {
            url = `/system/brand/list/t/${value}`;
          } else if (level == 1) {
            url = `/system/brand/list/s/${value}`;
          } else {
            url = '/system/brand/list';
          }
          get(url).then((res) => {
            if (level == 2) {
              nodes = res.data.map((item) => {
                return {
                  value: item.vehicleTypeId,
                  label: item.vehicleTypeName,
                  leaf: level >= 2,
                };
              });
            } else if (level == 1) {
              nodes = res.data.map((item) => {
                return {
                  value: item.vehicleSeriesId,
                  label: item.vehicleSeriesName,
                  leaf: level >= 2,
                };
              });
            } else {
              nodes = res.rows.map((item) => {
                return {
                  label: item.brandName,
                  value: item.brandId,
                  leaf: level >= 2,
                };
              });
            }
            resolve(nodes);
          });
        },
      },
    };
  },
};
</script>
